body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-color: #1f386b;
  --primary-color-background: #e9ecef;
  --secondary-color: #6c757d;
  --secondary-color-background: #f8f9fa;
  --success-color: #28a745;
  --success-color-background: #d4edda;
  --info-color: #17a2b8;
  --info-color-background: #c8e6c9;
  --warning-color: #ffc107;
  --warning-color-background: #fff7dc;
  --danger-color: #dc3545;
  --danger-color-background: #ffe3e6;
  --light-color: #f8f9fa;
  --dark-color: #343a40;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-table-thead .ant-table-cell {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
